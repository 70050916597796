import {
    hidden,
    visible,
    getDataByCodePostal,
    newInput,
    texteForNatureActe,
    texteForCessionPart,
    texteForGerances, cssVal
} from "../../helpers";
import {def} from "../../dictionnaire";


export const sc = {
    title: "SC Société Civile",
    texte: "{{statusGroup}} a été constituée la société suivante : <strong>Forme</strong> : {{societyGroup.societeFormeJuridique}} <strong>Dénomination : {{societyGroup.societeDenomination}}</strong>[[ <strong>Sigle</strong> : {{societyGroup.sigle}}]]. <strong>Siège</strong> : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}. <strong>Objet</strong> : {{societyGroup.objet}}. <strong>Durée</strong> : {{societyGroup.duree}} ans à compter de <strong>l'immatriculation au Rcs</strong> {{siegeSocialGroup.societeRcsVille}}. <strong>Capital</strong> : {{societyGroup.typeCapital}}. <strong>Gérance</strong> : {{gerancesGroup}}[[. {{cogerancesGroup}}]]. <strong>Cessions de parts</strong> : {{cessionPartGroup}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "statusGroup":
                tmpText = texteForNatureActe(varText);
                break;
            case "gerancesGroup":
                tmpText = texteForGerances(varText);
                break;
            case "cogerancesGroup":
                tmpText = texteForGerances(varText, 'cogerancesGroup');
                break;
            case "societyGroup.typeCapital":
                if(varText['societyGroup.typeCapital'] === 'variable') {
                    tmpText = `variable, initial : ${cssVal(varText["societyGroup.societeCapitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
                } else {
                    tmpText = ` ${cssVal(varText["societyGroup.societeCapital"])} €`
                }
                break;
            case "cessionPartGroup":
                tmpText = texteForCessionPart(varText);
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        siegeSocialGroup: [{}],
        gerancesGroup: [{}],
        cogerancesGroup: [{}],
    },
    schema: [
        {
            //0
            name: "statusGroup",
            label: "Les statuts",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            sc.schema[0].children[3] = val === "acte authentique" ? visible(sc.schema[0].children[3]) : hidden(sc.schema[0].children[3]);
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.formeSocieteSc, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.objet, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeCapital, '_required', {
                    on: {
                        input: async (val) => {
                            sc.schema[1].children[6] = val === "fixe" ? visible(sc.schema[1].children[6]) : hidden(sc.schema[1].children[6]);
                            sc.schema[1].children[7] = val === "variable" ? visible(sc.schema[1].children[7]) : hidden(sc.schema[1].children[7]);
                            sc.schema[1].children[8] = val === "variable" ? visible(sc.schema[1].children[8]) : hidden(sc.schema[1].children[8]);
                        }
                    }
                }),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
                newInput(def.capitalInitial, '_required', {name: 'societeCapitalInitial'}, 'hidden'),
                newInput(def.capitalMinimum, '_required', {}, 'hidden'),
            ]
        },
        {
            //2
            name: "siegeSocialGroup",
            label: "Adresse du siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sc.schema[2].children[3].options = data.options;
                            sc.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            sc.model.siegeSocialGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'disabled'),
            ]
        },
        {
            //3
            name: "gerancesGroup",
            label: "La gérance",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sc.schema[3].children[5].options = data.options;
                            sc.model.gerancesGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //4
            label: "Ajouter la Co-gérance",
            type: "button",
            on: {
                click: () => {
                    sc.schema[4].type = "hidden";
                    sc.schema[4].label = "";

                    sc.schema[5].type = "group";
                    sc.schema[5].label = "La Co-gérance";

                    sc.schema[6].type = "button";
                    sc.schema[6].label = "Supprimer la Co-gérance";
                }
            }
        },
        {
            //5
            name: "cogerancesGroup",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sc.schema[5].children[5].options = data.options;
                            sc.model.cogerancesGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //6
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    sc.schema[4].type = "button";
                    sc.schema[4].label = "Ajouter la Co-gérance";

                    sc.schema[5].type = "hidden";
                    sc.schema[5].label = "";

                    sc.schema[6].type = "hidden";
                    sc.schema[6].label = "";
                }
            }
        },
        {
            //7
            name: "cessionPartGroup",
            label: "Cessions de parts",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeCessionPart, '_required', {
                    on: {
                        input: (val) => {
                            sc.schema[7].children[1] = val === 'autre' ? visible(sc.schema[7].children[1]) : hidden(sc.schema[7].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        }
    ],
};